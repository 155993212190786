import { logEvent } from './helper';
import endpoints from '~/constants/endpoints';
import { getCookie } from '~/cookies';

import type { Location } from '@remix-run/react';
import type { User } from 'types';

// credit: https://stackoverflow.com/a/50101022
export const clientFetch = async (
  url: string,
  timeoutMS: number = 15000, // amount of time before the request is timed out
  { ...options }: RequestInit = {},
): Promise<Response> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
    logEvent({ name: 'ClientFetch::TimedOut' }, { url });
  }, timeoutMS);

  try {
    const response = await fetch(url, { signal: controller.signal, ...options });
    if (!response.ok) {
      logEvent(
        { name: 'ClientFetch::FailedRequest' },
        { url, status: response?.status, statusText: response?.statusText },
      );
    }
    return response;
  } catch (error) {
    logEvent({ name: 'ClientFetch::UnspecifiedError' }, { url, message: JSON.stringify(error) });
    throw error;
  } finally {
    // clear timeout after request is ended
    clearTimeout(timeout);
  }
};

export const openPopup = (url: string, title: string, w: number, h: number) => {
  // Credit - https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
  const { innerWidth, innerHeight, screenTop, screenLeft } = window;
  const { clientWidth, clientHeight } = document.documentElement;

  const width = innerWidth || clientWidth;
  const height = innerHeight || clientHeight;

  const left = width / 2 - w / 2 + screenLeft;
  const top = height / 2 - h / 2 + screenTop;
  const newWindow = window.open(
    url,
    title,
    'scrollbars=yes, rel=noopener, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
  );

  // Puts focus on the newWindow
  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }

  return newWindow;
};

export const clientRefreshUser = async (
  refreshToken: string,
  env: TSFix,
  browserIsSameSiteNoneCookieCompatible: boolean,
) => {
  const newTokenResponse = await fetch(endpoints.TOKENS_URL(env.AUTH_SERVICE_URL), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: env.CLIENT_AUTH_CLIENT_ID,
      client_secret: env.CLIENT_AUTH_CLIENT_SECRET,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    }),
  });

  const newTokens = await newTokenResponse.json();
  if (newTokens.access_token)
    document.cookie =
      'ACCESS_TOKEN=' + newTokens.access_token + (browserIsSameSiteNoneCookieCompatible ? '; SameSite=None' : '');
  if (newTokens.refresh_token)
    document.cookie =
      'REFRESH_TOKEN=' + newTokens.refresh_token + (browserIsSameSiteNoneCookieCompatible ? '; SameSite=None' : '');

  return { accessToken: newTokens.access_token as string, refreshToken: newTokens.refresh_token as string };
};

const getDomain = (env: TSFix) => {
  const url = env.CLIENT_URL;
  if (url) {
    const isDev = url.indexOf('flip-dev.com') > -1 || url.indexOf('flipgrid-dev.com') > -1;
    const isStg = url.indexOf('flip-stg.com') > -1 || url.indexOf('flipgrid-stg.com') > -1;
    const isLocalhost = url.indexOf('localhost') > -1;

    return isLocalhost || isDev ? 'flip-dev.com' : isStg ? 'flip-stg.com' : 'flip.com';
  }

  return 'flip.com';
};

const authUrl = (env: TSFix) => `https://auth.${getDomain(env)}`;

const clientAuthRedirectUrl = (location: Location, env: TSFix) => {
  const currentUrl = location.pathname;
  if (currentUrl === '/') return env.CLIENT_INFO_URL;

  return `${authUrl(env)}/signin?redirect_url=https://${getDomain(env)}${currentUrl}`;
};

/*
type GridToken = {
  gridToken: string;
  baseURL: string;
  vanityToken: string;
  guest: boolean;
  ltiToken?: string;
};

const getClientGridToken = async (location: Location, env: TSFix) => {
  const tokens = getCookie('GRID_TOKEN', document.cookie);

  console.log(tokens);

};
*/

export const getClientTokens = async (
  location: Location,
  env: TSFix,
  user: User,
  browserIsSameSiteNoneCookieCompatible: boolean,
) => {
  const accessToken = getCookie('ACCESS_TOKEN', document.cookie);
  const refreshToken = getCookie('REFRESH_TOKEN', document.cookie);

  if (user.canvasStudentUser) return undefined;
  /*
  const { gridToken } = await getClientGridToken(location, env);
  if (gridToken) return { gridToken };
  */

  // if they're both valid return 'em
  if (accessToken && refreshToken) {
    return { accessToken, refreshToken };
  }
  // if only AT is valid return it and go along since its still a valid state
  if (accessToken) {
    return { accessToken };
  }
  // if accessToken is expired
  if (refreshToken) {
    let refreshTries = 0;
    while (refreshTries < 3) {
      // eslint-disable-next-line no-await-in-loop
      const tokens = await clientRefreshUser(refreshToken, env, browserIsSameSiteNoneCookieCompatible);
      if (tokens?.accessToken) return tokens;
      refreshTries += 1;
    }

    // Redirect to info
    window.location.href = env.CLIENT_INFO_URL;
  }

  // if refreshToken does not have a value or is expired redirect to entrypoint or /login once that exists
  window.location.href = clientAuthRedirectUrl(location, env);
};
